var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manager-container" }, [
    _c("div", { staticClass: "button-block" }, [
      _c(
        "div",
        { staticClass: "query-item", staticStyle: { "margin-left": "0px" } },
        [
          _c("span", [_vm._v("IMO")]),
          _c(
            "el-select",
            {
              style: { width: "200px" },
              attrs: { placeholder: "Select Barge/Vessel" },
              model: {
                value: _vm.filter.select,
                callback: function ($$v) {
                  _vm.$set(_vm.filter, "select", $$v)
                },
                expression: "filter.select",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("el-input", {
            style: { width: "200px", paddingLeft: "10px" },
            attrs: { placeholder: "Search Barge/Vessel IMO" },
            model: {
              value: _vm.filter.imo,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "imo", $$v)
              },
              expression: "filter.imo",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "query-item" },
        [
          _c("span", [_vm._v("Date")]),
          _c("el-date-picker", {
            attrs: {
              type: "datetimerange",
              size: "mini",
              "range-separator": "to",
              "start-placeholder": "Start Date",
              "end-placeholder": "End Date",
            },
            model: {
              value: _vm.filter.date,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "date", $$v)
              },
              expression: "filter.date",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "query-item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.query },
            },
            [_vm._v(_vm._s(_vm.$t("query")))]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.reset } },
            [_vm._v(_vm._s(_vm.$t("reset")))]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "table-block" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": _vm.HEADER_CELL_STYLE,
              "cell-style": _vm.CELL_STYLE,
              stripe: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "Date Time", width: "150px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.createdAt
                              ? _vm
                                  .$moment(scope.row.createdAt)
                                  .format("YYYY-MM-DD HH:mm:ss")
                              : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                label: "Device",
                width: "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.deviceId)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                label: "Job ID",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.jobId)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Barge IMO", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.ebdnJob ? scope.row.ebdnJob.bargeImo : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Vessel IMO", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.ebdnJob ? scope.row.ebdnJob.vesselImo : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                label: "Event",
                "min-width": "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.event)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                label: "Message",
                "min-width": "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticStyle: { "white-space": "pre" } }, [
                        _vm._v(_vm._s(scope.row.message)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pagination-container" },
      [
        _vm.tableData.length > 0
          ? _c("el-pagination", {
              attrs: {
                small: "",
                "page-size": _vm.pagination.pageSize,
                "page-sizes": [10, 25, 50, 100],
                "current-page": _vm.pagination.pageNumber,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pagination.total,
              },
              on: {
                "size-change": _vm.handlePageSizeChange,
                "current-change": _vm.handlePageChange,
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.pagination, "pageSize", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.pagination, "pageSize", $event)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }